import _ from 'lodash';

export function styles<TRecord extends Record<string, unknown>>(styleData: TRecord) {
	return Object.entries(styleData)
		.map(([key, value]) => {
			if (!_.isNil(styleData[key as keyof TRecord])) {
				return `${key}: ${value === true ? 1 : value};`;
			}
			return '';
		})
		.join('');
}
